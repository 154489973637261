export const hs = function() {
    return 'host';
}

export const ns = function() {
    return 'lo'
}

export const bs = function() {
    return 'to'
}